import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebookF,
  FaMailBulk,
  FaInstagram,
  FaPhoneAlt,
  FaArrowRight,
} from "react-icons/fa";
import logo from "../../Assests/Handa_white_logo.webp";
import qr from "../../Assests/qrcode.webp";
import footerlogo from "../../Assests/footer-logo.dd0be9f6.svg";

export default function FooterComponent() {
  return (
    <footer className="py-4" style={{ backgroundColor: "#233243" }}>
      <Container>
        <Row className="justify-content-center">
          <Col md={3} className="d-flex flex-column align-items-center">
            <img
              src={logo}
              alt="Logo"
              className="mb-3 img-fluid"
              style={{ width: "55%" }}
            />

            <div className="social-icons d-flex flex-column align-items-start text-white">
              <div className="d-flex align-items-center mb-2">
                <FaFacebookF className="me-2" />
                <a href="#facebook" className="text-white">
                  Facebook
                </a>
              </div>

              <div className="d-flex align-items-center mb-2">
                <FaInstagram className="me-2" />
                <a href="#instagram" className="text-white">
                  Instagram
                </a>
              </div>

              <div className="d-flex align-items-center">
                <FaPhoneAlt className="me-2" />
                <a href="tel:+16479876291" className="text-white">
                  +1 (647) 987-6291
                </a>
              </div>

              <div className="d-flex align-items-center mb-2">
                <FaMailBulk className="me-2" />
                <a href="mailto:handa.realtor@gmail.com" className="text-white">
                  handa.realtor@gmail.com
                </a>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <h5 className="text-white">Quick Links</h5>
            <div className="d-flex flex-column align-items-start text-white">
              <ul className="list-unstyled">
                <li className="mb-2">
                  <FaArrowRight className="me-2" />
                  <a href="#home" className="text-white">
                    Home
                  </a>
                </li>
                <li className="mb-2">
                  <FaArrowRight className="me-2" />
                  <a href="#buy" className="text-white">
                    Buy
                  </a>
                </li>
                <li className="mb-2">
                  <FaArrowRight className="me-2" />
                  <a href="#rent" className="text-white">
                    Rent
                  </a>
                </li>
                <li className="mb-2">
                  <FaArrowRight className="me-2" />
                  <a href="#sell" className="text-white">
                    Sell
                  </a>
                </li>
                <li className="mb-2">
                  <FaArrowRight className="me-2" />
                  <a href="#about-us" className="text-white">
                    About Us
                  </a>
                </li>
                <li>
                  <FaArrowRight className="me-2" />
                  <a href="#contact-us" className="text-white">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </Col>

          <Col md={3}>
            <h5 className="text-white">Scan QR Code Now</h5>
            <div>
              <img
                src={qr}
                alt="QR Code 1"
                style={{ width: "65%", marginBottom: "10px" }}
              />
            </div>
            <div>
              <img src={footerlogo} alt="QR Code 2" style={{ width: "90%" }} />
            </div>
          </Col>

          <Col md={3}>
            <h5 className="text-white">Newsletter</h5>
            <p className="text-white">
              Sign Up to Our Newsletter to Get Latest Updates & Services
            </p>
            <input
              type="email"
              placeholder="Enter your email"
              className="form-control mb-2"
            />
            <button className="btn common__btn text-white">Send</button>
          </Col>
          <hr />
          <Row className="justify-content-between text-white mt-4">
            <Col md={6} className="text-start" style={{ fontSize: "1.2rem" }}>
              © 2024 Canadian Home. All Rights Reserved. Designed by WEBWIDEIT
            </Col>
            <Col md={6} className="text-end">
              <a href="#terms" className="text-white me-3">
                Terms & Conditions
              </a>
              <a href="#privacy" className="text-white">
                Privacy Policy
              </a>
            </Col>
          </Row>
        </Row>
      </Container>
    </footer>
  );
}