import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import { FaTag, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import waterwheel from "../../Assests/waterwheel-street-markham-ontario-4.webp";

const PropertyList = () => {
  const [data, setPropertyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiToken = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjI5MiIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzI4OTcyNjMzLCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2MjkyIiwianRpIjoiODQ3NTJlZTljNjY0MTQxZCIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.ONBoLBhod6XnmgGtoXaypodtECSd83RRZw5vLU8bGJI';

  useEffect(() => {
    const fetchPropertyData = async () => {
      setError(null);
      try {
        const response = await fetch(`https://properties.handarealtor.ca/api/odata/Property-List`, {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        });

        if (!response.ok) throw new Error(`Error: ${response.statusText}`);
        const result = await response.json();
        setPropertyData(result.value);

      } catch (err) {
        setError(err.message);
        console.error("Failed to fetch property data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [apiToken]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <Container className="mt-5">
      {data.length > 0 ? (
        <Row>
          {data.map((property) => (
            <Col md={6} lg={4} key={property.ListingKey} className="mb-4">
              <Card className="shadow-sm h-100">
                <div style={{ position: 'relative' }}>
                  <Link to={`/property-details/${encodeURIComponent(`${property.BedroomsTotal || '0'}-${property.PropertySubType?.toLowerCase() || ''}-${property.StreetNumber}-${property.StreetName?.toLowerCase() || ''}-${property.StreetSuffix?.toLowerCase() || ''}-${property.City?.toLowerCase() || ''}-${property.StateOrProvince?.toLowerCase() || ''}-${property.ListingKey.toLowerCase() || ''}`).replace(/%20/g, '-')}`}>
                    <Card.Img
                      variant="top"
                      src={waterwheel} // Static image for all properties
                      alt={`${property.BedroomsTotal} Bedroom ${property.PropertySubType} at ${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}, ${property.City}, ${property.StateOrProvince}`}
                    />
                  </Link>
                  <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                    <Badge bg="danger">
                      {property.TransactionType === 'For Lease' ? 'For Buy' : property.TransactionType}
                    </Badge>
                  </div>
                </div>
                <Card.Body>
                  <Card.Title>{`${property.BedroomsTotal || '0'} Bedroom ${property.PropertySubType} at ${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}, ${property.City}, ${property.StateOrProvince}`}</Card.Title>
                  <Card.Text>
                    <FaMapMarkerAlt /> {property.UnparsedAddress}
                  </Card.Text>
                  <Card.Text>
                    <FaTag /> Price: ${property.ListPrice}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="text-center">No property details available</div>
      )}
    </Container>
  );
};

export default PropertyList;
