import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../../Assests/Handa_block_logo.webp";

export default function Navbarcomponent() {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/" className="d-flex align-items-center">
                    <img
                        src={logo}
                        alt="Logo"
                        className="home__logo__navbar-logo img-fluid"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link
                            href="#"
                            className="nav-link-custom home__list__links__custom"
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            href="#"
                            className="nav-link-custom home__list__links__custom"
                        >
                            Buy
                        </Nav.Link>
                        <Nav.Link
                            href="#"
                            className="nav-link-custom home__list__links__custom"
                        >
                            Rent
                        </Nav.Link>
                        <Nav.Link
                            href="#"
                            className="nav-link-custom home__list__links__custom"
                        >
                            Sell
                        </Nav.Link>
                        <Nav.Link
                            href="#"
                            className="nav-link-custom home__list__links__custom"
                        >
                            About Us
                        </Nav.Link>
                        <Nav.Link
                            href="#"
                            className="nav-link-custom home__list__links__custom"
                        >
                            Contact Us
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
