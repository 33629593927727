import React, { useEffect, useState } from 'react';

const MediaList = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  const fetchPropertyImages = async () => {
    try {
      const response = await fetch('http://localhost:8081/api/odata/Media');

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const propertyImages = data.value.filter(item => item.MediaCategory === "Photo");
      console.log(propertyImages);
      setImages(propertyImages);
      setError(null); // Reset error state on successful fetch
    } catch (error) {
      console.error('Error fetching property images:', error);
      setError(error.message); // Set error message to state
    }
  };

  useEffect(() => {
    fetchPropertyImages();
  }, []);

  return (
    <div>
      <h2>Property Images</h2>
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
      {images.length === 0 ? (
        <p>No images found.</p>
      ) : (
        images.map(item => (
          <div key={`${item.MediaObjectID}-${item.ShortDescription}`}>
            <h3>{item.ShortDescription}</h3>
            <img src={item.MediaURL} alt={item.ShortDescription} style={{ width: '300px', height: 'auto' }} />
          </div>
        ))
      )}
    </div>
  );
};

export default MediaList;
