import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  ListGroup,
  Image,
  Carousel,
  Badge,
} from "react-bootstrap";
import {
  FaTag,
  FaHome,
  FaCar,
  FaBed,
  FaShower,
  FaExpand,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaClock,
  FaEye,
  FaFlag,
} from "react-icons/fa";
import waterwheel from "../../Assests/waterwheel-street-markham-ontario-4.webp";
import thumbnil from "../../Assests/waterwheel-street-markham-ontario-4-150x120.webp";
import { Link, useParams } from 'react-router-dom';

const Properties = () => {
  const [data, setPropertyData] = useState(null); // State to store fetched property data
  const [loading, setLoading] = useState(true); // State to show loading state
  const [error, setError] = useState(null); // State to handle errors

  const [activeIndex, setActiveIndex] = useState(0);

  // Dummy images - You may replace these with images fetched from the property data
  const mainImages = [waterwheel, waterwheel, waterwheel, waterwheel, waterwheel];
  const thumbnailImages = [thumbnil, thumbnil, thumbnil, thumbnil, thumbnil];

  const apiToken = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjI5MiIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzI4OTcyNjMzLCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2MjkyIiwianRpIjoiODQ3NTJlZTljNjY0MTQxZCIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.ONBoLBhod6XnmgGtoXaypodtECSd83RRZw5vLU8bGJI'; // Ensure to replace with actual token
  const { listingKey } = useParams();
  const [similarProperties, setSimilarProperties] = useState([]);
  const [expanded, setExpanded] = useState(null);


  // Function to toggle the expanded state
  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };


  const parseListingKey = (key) => {
    const parts = key.split('-'); // Split by the hyphen
    const streetNumber = parts[0]; // 2
    const propertyType = parts[1]; // detached
    const streetName = parts.slice(2, -3).join('-'); // 27-kilbride-road
    const city = parts[parts.length - 3]; // toronto
    const stateOrProvince = parts[parts.length - 2]; // e09
    const actualListingKey = parts[parts.length - 1].toUpperCase(); // E9296138

    return { streetNumber, propertyType, streetName, city, stateOrProvince, actualListingKey };
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const TimeAgo = ({ modificationTimestamp }) => {
    const formatTimeAgo = (timestamp) => {
      const now = new Date();
      const date = new Date(timestamp);
      const secondsDiff = Math.floor((now - date) / 1000);
      let interval = secondsDiff / 31536000; // years

      if (interval > 1) {
        return `Added ${Math.floor(interval)} years ago`;
      }
      interval = secondsDiff / 2592000; // months
      if (interval > 1) {
        return `Added ${Math.floor(interval)} months ago`;
      }
      interval = secondsDiff / 86400; // days
      if (interval > 1) {
        return `Added ${Math.floor(interval)} days ago`;
      }
      interval = secondsDiff / 3600; // hours
      if (interval > 1) {
        return `Added ${Math.floor(interval)} hours ago`;
      }
      interval = secondsDiff / 60; // minutes
      if (interval > 1) {
        return `Added ${Math.floor(interval)} minutes ago`;
      }
      return 'Added just now';
    };

    return (
      <span className="time">{formatTimeAgo(modificationTimestamp)}</span>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { actualListingKey } = parseListingKey(listingKey);
        const response = await axios.get(`https://properties.handarealtor.ca/api/odata/Property/${actualListingKey}`); // Use the new endpoint
        setPropertyData(response.data); // Set the property data in state
      } catch (err) {
        console.error('Error fetching property data:', err);
        setError('Could not fetch property data. Please try again later.'); // More user-friendly error
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [listingKey]);

  useEffect(() => {
    const fetchSimilarProperties = async () => {
      try {
        const response = await axios.get('https://properties.handarealtor.ca/api/odata/Property-List');
        const properties = response.data.value; // Adjust based on your API response structure

        // Define your logic for finding similar properties
        const similar = properties.filter(property =>
          property.PropertySubType === data.PropertySubType
        );

        // Only take the first 2 similar properties
        const limitedSimilarProperties = similar.slice(0, 2);

        setSimilarProperties(limitedSimilarProperties);
      } catch (error) {
        console.error('Error fetching similar properties:', error.message);
      }
    };

    fetchSimilarProperties();
  }, [data]);


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {data ? (
        <div className="bg__properties__color py-5">
          <Container>
            <Row className="mb-4">
              <Col>
                <div className="d-flex align-items-center mb-2">
                  <Badge bg="warning" text="dark" className="me-2 property__new__items">
                    {data.TransactionType === 'For Lease' ? 'For Buy' : data.TransactionType}
                  </Badge>
                  <Badge bg="danger" className="property__new__items">New</Badge>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h2>{`${data.BedroomsTotal != null ? data.BedroomsTotal : '0'} Bedroom ${data.PropertySubType} at ${data.StreetNumber} ${data.StreetName} ${data.StreetSuffix}, ${data.City}, ${data.StateOrProvince}`}</h2>
                    <p>{data.UnparsedAddress}</p>
                  </div>
                  <h3 className="text-warning mb-0">${data.ListPrice || 'N/A'} (Fixed)</h3>
                </div>
                <div className="d-flex align-items-center">
                  <span><FaClock /> {new Date(data.OriginalEntryTimestamp).toLocaleDateString()}</span>
                  <span className="ms-3"><FaEye /> Views: {data.View || 'N/A'}</span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <Card style={{ backgroundColor: "transparent", border: "0" }}>
                  <Carousel activeIndex={activeIndex} onSelect={handleSelect} indicators={false}>
                    {mainImages.map((image, index) => (
                      <Carousel.Item key={index}>
                        <Image src={image} alt={`Property Image ${index + 1}`} fluid />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <Card.Body style={{ padding: "10px 0 0 0" }}>
                    <div className="d-flex justify-content-between">
                      {thumbnailImages.map((thumbnail, index) => (
                        <Image
                          key={index}
                          src={thumbnail}
                          alt={`Thumbnail ${index + 1}`}
                          thumbnail
                          onClick={() => setActiveIndex(index)}
                          style={{ cursor: "pointer" }}
                        />
                      ))}
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card>
                  <Card.Body>
                    <h5>Contact Admin</h5>
                    <ListGroup variant="flush" className="mb-4">
                      <ListGroup.Item>Phone: +1 (647) 987-6XXX</ListGroup.Item>
                      <ListGroup.Item>WhatsApp: +1 (647) 987-6XXX</ListGroup.Item>
                    </ListGroup>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="Name" required />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control type="email" placeholder="Email" required />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control type="tel" placeholder="Phone" required />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={3} placeholder="Message" required />
                      </Form.Group>
                      <Button variant="warning" type="submit">
                        Send Message
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Overview Section */}
            <Row className="mt-4">
              <Col md={8}>
                <div className="bg-white p-3 rounded shadow-sm">
                  <h4 className="mb-4">Overview</h4>
                  <div class="property-card">
                    <div class="property-header">
                      <span class="status"> {data.TransactionType === 'For Lease' ? 'For Buy' : data.TransactionType}</span>
                      <span class="time"><TimeAgo modificationTimestamp={data.ModificationTimestamp} /></span>
                      <span class="price">Listed for: <span class="amount">${data.ListPrice}</span></span>
                    </div>
                    <h2 class="property-title">{`${data.BedroomsTotal != null ? data.BedroomsTotal : '0'} Bedroom ${data.PropertySubType} at ${data.StreetNumber} ${data.StreetName} ${data.StreetSuffix}, ${data.City}, ${data.StateOrProvince}`}</h2>
                    <p class="location">{data.UnparsedAddress}</p>
                    <div class="property-details">
                      <span class="icon-text"><i class="icon-bed"></i>  {data.BedroomsTotal !== null ? data.BedroomsTotal : '0'} Bedrooms</span>
                      <span class="icon-text"><i class="icon-bath"></i> {data.BathroomsTotalInteger !== null ? data.BathroomsTotalInteger : '0'} Bedrooms</span>
                      <span class="icon-text"><i class="icon-garage"></i>{data.GarageType} Garage</span>
                    </div>

                  </div>

                  <div class="property-details-card">
                    <h3 class="property-title">Key Facts About {`${data.BedroomsTotal != null ? data.BedroomsTotal : '0'} Bedroom ${data.PropertySubType} at ${data.StreetNumber} ${data.StreetName} ${data.StreetSuffix}, ${data.City}, ${data.StateOrProvince}`}</h3>
                    <div class="property-facts">
                      <div class="fact">
                        <span class="label">Tax</span>
                        <span className="value">
                          {data.TaxAnnualAmount !== null && data.TaxYear !== null
                            ? `$${data.TaxAnnualAmount} / ${data.TaxYear}`
                            : 'N/A'} {/* Replace 'N/A' with your desired fallback value */}
                        </span>

                      </div>
                      <div class="fact">
                        <span class="label">Listing #</span>
                        <span class="value">{data.ListingKey}</span>
                      </div>
                      <div class="fact">
                        <span class="label">Property Type</span>
                        <span class="value">{data.PropertyType}</span>
                      </div>
                      <div class="fact">
                        <span class="label">Size</span>
                        <span class="value">TRREB</span>
                      </div>
                      <div class="fact">
                        <span class="label">Building Type</span>
                        <span class="value">{data.PropertyType}</span>
                      </div>
                      <div class="fact">
                        <span class="label">Listed On</span>
                        <span className="value">
                          {new Date(data.OriginalEntryTimestamp).toLocaleString('en-GB', {
                            dateStyle: 'short',
                            timeStyle: 'short'
                          })}
                        </span>

                      </div>
                      <div class="fact">
                        <span class="label">Building Style</span>
                        <span class="value">{data.ArchitecturalStyle}</span>
                      </div>
                      <div class="fact">
                        <span class="label">Updated On</span>
                        <span className="value">
                          {new Date(data.ModificationTimestamp).toLocaleString('en-GB', {
                            dateStyle: 'short',
                            timeStyle: 'short'
                          })}
                        </span>

                      </div>
                      <div class="fact">
                        <span class="label">Maintenance Fees</span>
                        <span class="value">{data.AssociationFee !== null ? data.AssociationFee : 'N/A'}</span>
                      </div>
                      <div class="fact">
                        <span class="label">Market Demand</span>
                        <span class="value market-demand">
                          <span class="market-bar">
                            <span class="demand-indicator" style={{ left: "50%" }}></span>
                          </span>
                          <span class="market-labels">
                            <span>Buyer's Market</span>
                            <span>Balanced</span>
                            <span>Seller's Market</span>
                          </span>
                        </span>
                      </div>
                      <div class="fact">
                        <span class="label">Size</span>
                        <span class="value">{data.LivingAreaRange !== null ? data.LivingAreaRange : 'N/A'}</span>
                      </div>
                      <div class="fact">
                        <span class="label">Parking</span>
                        <span class="value">{data.GarageType} garage, {data.ParkingTotal} parking</span>
                      </div>
                    </div>
                    <h4 class="description-title">Description</h4>
                    <p class="description-text">
                      {data.PublicRemarks}
                    </p>
                  </div>

                  <div class="property-details-card">
                    <h3 class="property-title">Property Details of  {`${data.BedroomsTotal != null ? data.BedroomsTotal : '0'} Bedroom ${data.PropertySubType} at ${data.StreetNumber} ${data.StreetName} ${data.StreetSuffix}, ${data.City}, ${data.StateOrProvince}`}</h3>
                    <div class="property-facts">
                      <div class="fact">
                        <span class="label">Property</span>

                      </div>
                      <div class="fact">
                        <span class="label">Building </span>
                      </div>
                      <div class="fact">
                        <span class="">Property Type</span>
                        <span class="value">{data.PropertyType}</span>
                      </div>
                      <div class="fact">
                        <span class="">Size</span>
                        <span class="value">{data.LivingAreaRange != null ? data.LivingAreaRange : 'N/A'}</span>
                      </div>
                      <div class="fact">
                        <span class="">Style</span>
                        <span className="value">
                          {Array.isArray(data.ArchitecturalStyle) && data.ArchitecturalStyle.length > 0
                            ? data.ArchitecturalStyle.join(', ')
                            : 'N/A'}
                        </span>

                      </div>
                      <div class="fact">
                        <span class="">Architecture Style</span>
                        <span className="value">
                          {Array.isArray(data.ArchitecturalStyle) && data.ArchitecturalStyle.length > 0
                            ? data.ArchitecturalStyle.join(', ')
                            : 'N/A'}

                        </span>

                      </div>
                      <div class="fact">
                        <span class="">Community</span>
                        <span className="value">
                          {data.CommunityFeatures && data.CommunityFeatures.length > 0
                            ? data.CommunityFeatures.join(', ')
                            : 'N/A'}
                        </span>
                      </div>
                      <div class="fact">
                        <span class="">Exterior Feature</span>
                        <span className="value">
                          {Array.isArray(data.ExteriorFeatures) && data.ExteriorFeatures.length > 0
                            ? data.ExteriorFeatures.join(', ')
                            : 'N/A'}
                        </span>

                      </div>
                      <div class="fact">
                        <span class="">Municipality</span>
                        <span class="value">{data.OutOfAreaMunicipality !== null ? data.OutOfAreaMunicipality : 'N/A'}</span>
                      </div>
                      <div class="fact">
                        <span class="">Amenities</span>
                        <span class="value market-demand">

                          {Array.isArray(data.AssociationAmenities) && data.AssociationAmenities.length > 0
                            ? data.AssociationAmenities.join(', ')
                            : 'N/A'}
                        </span>
                      </div>
                      <div class="fact">
                        <span class="label">Inside</span>
                      </div>
                      <div class="fact">
                        <span class="label"> </span>
                      </div>
                      <div class="fact">
                        <span class="">Bedrooms</span>
                        <span class="value">{data.BedroomsTotal != null ? data.BedroomsTotal : 'N/A'}</span>
                      </div>
                      <div class="fact">
                        <span class="">Parking</span>
                        <span class="value">{data.ParkingTotal != null ? data.ParkingTotal : 'N/A'}</span>
                      </div>

                      <div class="fact">
                        <span class="">Bathrooms</span>
                        <span class="value">{data.BathroomsTotalInteger != null ? data.BathroomsTotalInteger : 'N/A'}</span>
                      </div>
                      <div class="fact">
                        <span class="">Garage Type</span>
                        <span class="value">{data.GarageType != null ? data.GarageType : 'N/A'}</span>
                      </div>

                      <div class="fact">
                        <span class="">Kitchen</span>
                        <span class="value">{data.KitchensTotal != null ? data.KitchensTotal : 'N/A'}</span>
                      </div>
                      <div class="fact">
                        <span class="">Rooms</span>
                        <span class="value">{data.RoomsTotal != null ? data.RoomsTotal : 'N/A'}</span>
                      </div>

                      <div class="fact">
                        <span class="">Family Rooms</span>
                        <span class="value">N</span>
                      </div>
                      <div class="fact">
                        <span class="">Parking Places</span>
                        <span class="value">{data.ParkingSpaces != null ? data.ParkingSpaces : 'N/A'}</span>
                      </div>


                      <div class="fact">
                        <span class="">Fireplace</span>
                        <span class="value">  {Array.isArray(data.FireplaceFeatures) && data.FireplaceFeatures.length > 0
                          ? data.FireplaceFeatures.join(', ')
                          : 'N/A'}</span>
                      </div>
                      <div class="fact">
                        <span class="">Total Parking Places</span>
                        <span class="value">{data.ParkingTotal != null ? data.ParkingTotal : 'N/A'}</span>
                      </div>

                      <div class="fact">
                        <span class="label">Utilities</span>
                      </div>
                      <div class="fact">
                        <span class="label">Land</span>
                      </div>

                      <div class="fact">
                        <span class="">Cooling</span>
                        <span class="value">{data.Cooling}</span>
                      </div>
                      <div class="fact">
                        <span class="">Cross Street</span>
                        <span class="value">{data.CrossStreet != null ? data.CrossStreet : 'N/A'}</span>
                      </div>

                      <div class="fact">
                        <span class="">Heating Type</span>
                        <span class="value">{data.HeatType}</span>
                      </div>
                      <div class="fact">
                        <span class=""></span>
                        <span class="value"></span>
                      </div>

                      <div class="fact">
                        <span class="">Heating Fuel</span>
                        <span class="value">{data.HeatSource}</span>
                      </div>
                      <div class="fact">
                        <span class=""></span>
                        <span class="value"></span>
                      </div>


                    </div>


                  </div>
                </div>

                <div className="bg-white p-3 mt-4 rounded shadow-sm">
                  <div className="item__heading__properties mb-3">
                    <h3><b>Similar Properties</b></h3>
                  </div>

                  <Row>
                    {/* Display the first two properties directly */}
                    {similarProperties.slice(0, 2).map((property, index) => (
                      <Col key={index} className="mb-4" md={6}>
                        <Card className="shadow-sm h-100">
                          <div style={{ position: 'relative' }}>
                            <Card.Img
                              variant="top"
                              src={waterwheel}
                              alt={`${property.BedroomsTotal} Bedroom ${property.PropertySubType} at ${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}, ${property.City}, ${property.StateOrProvince}`}
                            />
                            <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                              <Badge bg="danger">
                                {property.TransactionType === 'For Lease' ? 'For Buy' : property.TransactionType}
                              </Badge>
                            </div>
                            <Link
                              to={`/property-details/${encodeURIComponent(
                                `${property.BedroomsTotal != null ? property.BedroomsTotal : '0'}-${property.PropertySubType?.toLowerCase() || ''}-${property.StreetNumber}-${property.StreetName?.toLowerCase() || ''}-${property.StreetSuffix?.toLowerCase() || ''}-${property.City?.toLowerCase() || ''}-${property.StateOrProvince?.toLowerCase() || ''}-${property.ListingKey.toLowerCase() || ''}`
                              )}`}
                              style={{ position: 'absolute', bottom: '10px', left: '10px' }}
                            >
                              View Details
                            </Link>
                          </div>
                          <Card.Body>
                            <Card.Title>{`${property.BedroomsTotal !== null ? property.BedroomsTotal : '0'} Bedroom ${property.PropertySubType} at ${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}, ${property.City}, ${property.StateOrProvince}`}</Card.Title>
                            <Card.Text>
                              <FaMapMarkerAlt /> {property.UnparsedAddress}
                            </Card.Text>
                            <Card.Text>
                              <FaTag /> Price: ${property.ListPrice}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  {/* Only show the carousel if there are more than 2 properties */}
                  {similarProperties.length > 2 && (
                    <Carousel className="mt-4">
                      {similarProperties.slice(2).map((property, index) => (
                        <Carousel.Item key={index}>
                          <Row>
                            <Col className="mb-4" md={6}>
                              <Card className="shadow-sm h-100">
                                <div style={{ position: 'relative' }}>
                                  <Card.Img
                                    variant="top"
                                    src={waterwheel}
                                    alt={`${property.BedroomsTotal} Bedroom ${property.PropertySubType} at ${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}, ${property.City}, ${property.StateOrProvince}`}
                                  />
                                  <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                                    <Badge bg="danger">
                                      {property.TransactionType === 'For Lease' ? 'For Buy' : property.TransactionType}
                                    </Badge>
                                  </div>
                                  <Link
                                    to={`/property-details/${encodeURIComponent(
                                      `${property.BedroomsTotal != null ? property.BedroomsTotal : '0'}-${property.PropertySubType?.toLowerCase() || ''}-${property.StreetNumber}-${property.StreetName?.toLowerCase() || ''}-${property.StreetSuffix?.toLowerCase() || ''}-${property.City?.toLowerCase() || ''}-${property.StateOrProvince?.toLowerCase() || ''}-${property.ListingKey.toLowerCase() || ''}`
                                    )}`}
                                    style={{ position: 'absolute', bottom: '10px', left: '10px' }}
                                  >
                                    View Details
                                  </Link>
                                </div>
                                <Card.Body>
                                  <Card.Title>{`${property.BedroomsTotal !== null ? property.BedroomsTotal : '0'} Bedroom ${property.PropertySubType} at ${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}, ${property.City}, ${property.StateOrProvince}`}</Card.Title>
                                  <Card.Text>
                                    <FaMapMarkerAlt /> {property.UnparsedAddress}
                                  </Card.Text>
                                  <Card.Text>
                                    <FaTag /> Price: ${property.ListPrice}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  )}
                </div>

                <div className="bg-white p-3 mt-4 rounded shadow-sm">
                  <div className="item__heading__properties mb-3">
                    <h3>
                      About - {`${data.BedroomsTotal != null ? data.BedroomsTotal : '0'} Bedroom ${data.PropertySubType} at ${data.StreetNumber} ${data.StreetName} ${data.StreetSuffix}, ${data.City}, ${data.StateOrProvince}`}
                    </h3>
                  </div>
                  <div className="items__subheading__properties mb-3">
                    <p>{data.PublicRemarks}</p>
                  </div>
                  <div className="items__subheading__properties mb-3">
                    <p>{data.PublicRemarksExtras}</p>
                  </div>

                  {/* FAQs Section */}
                  <div className="faqs-section mt-4">
                    <h4 className="faq-section-title"><b>FAQs About {`${data.BedroomsTotal != null ? data.BedroomsTotal : '0'} Bedroom ${data.PropertySubType} at ${data.StreetNumber} ${data.StreetName} ${data.StreetSuffix}, ${data.City}, ${data.StateOrProvince}`}</b></h4>

                    {/* FAQ Item 1 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(1)}>
                        <h5 className="faq-question">How many bathrooms does this property have?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 1 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 1 && <p className="faq-answer">This property features {data.BathroomsTotalInteger} bathroom(s) and {data.BedroomsTotal} bedroom(s).</p>}
                    </div>

                    {/* FAQ Item 2 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(2)}>
                        <h5 className="faq-question">Does this {data.BedroomsTotal != null ? data.BedroomsTotal : '0'} bedroom property have additional bedrooms or a den?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 2 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 2 && <p className="faq-answer">This property does not have additional bedrooms.</p>}
                    </div>

                    {/* FAQ Item 3 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(3)}>
                        <h5 className="faq-question">What is the square footage (sqft) of this property?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 3 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 3 && <p className="faq-answer">The total square footage of this  property is {data.SquareFootSource != null ? data.SquareFootSource : 'N/A'}</p>}
                    </div>

                    {/* FAQ Item 4 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(4)}>
                        <h5 className="faq-question">How many parking spaces are available with this property?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 4 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 4 && <p className="faq-answer">{`${data.PropertySubType} at ${data.StreetNumber} ${data.StreetName} ${data.StreetSuffix}, ${data.City}, ${data.StateOrProvince}`} boasts <b>{data.ParkingSpaces}</b> convenient parking spaces.</p>}
                    </div>

                    {/* FAQ Item 5 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(5)}>
                        <h5 className="faq-question">What type of garage does this property have?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 5 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 5 && <p className="faq-answer">This property includes an {data.GarageType} garage.</p>}
                    </div>

                    {/* FAQ Item 6 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(6)}>
                        <h5 className="faq-question">Does this property have a basement?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 6 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 6 && <p className="faq-answer">There is no basement in this property.</p>}
                    </div>


                    {/* FAQ Item 7 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(7)}>
                        <h5 className="faq-question">What type of basement is it?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 7 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 7 && <p className="faq-answer">Information about basement type is {data.Basement !== null ? data.Basement : 'not available'}.</p>}
                    </div>

                    {/* FAQ Item 8 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(8)}>
                        <h5 className="faq-question">Does this property have a swimming pool?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 8 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 8 && <p className="faq-answer">It is not specified whether this property has a swimming pool.</p>}
                    </div>

                    {/* FAQ Item 9 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(9)}>
                        <h5 className="faq-question">Does this property have a fireplace?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 9 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 9 && <p className="faq-answer"> There is {data.FireplaceYN == null ? (data.FireplaceYN ? '' : 'no ') : 'no '} fireplace in this property.</p>}
                    </div>


                    {/* FAQ Item 10 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(10)}>
                        <h5 className="faq-question">How many kitchens does this property have?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 10 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 10 && <p className="faq-answer"> This property includes {data.KitchensTotal !== null ? data.KitchensTotal : '0'} kitchen(s).</p>}
                    </div>

                    {/* FAQ Item 11 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(11)}>
                        <h5 className="faq-question">Is there a maintenance fee associated with this property?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 11 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 11 && <p className="faq-answer"> Information about the maintenance fee is not available.</p>}
                    </div>

                    {/* FAQ Item 12 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(12)}>
                        <h5 className="faq-question">Is this property furnished?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 12 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 12 && <p className="faq-answer">{data.Furnished == null ? (data.Furnished ? '' : 'No ') : 'No '},this property is not furnished.</p>}
                    </div>


                    {/* FAQ Item 13 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(13)}>
                        <h5 className="faq-question">What type of heating system is in place?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 13 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 13 && <p className="faq-answer">The heating system in place is a {data.HeatSource}.</p>}
                    </div>

                    {/* FAQ Item 14 */}
                    <div className="faq-item mb-3">
                      <div className="faq-header" onClick={() => toggleExpand(14)}>
                        <h5 className="faq-question">What utilities are available for this property?</h5>
                        <button className="faq-toggle-btn">
                          {expanded === 14 ? '-' : '+'}
                        </button>
                      </div>
                      {expanded === 14 && <p className="faq-answer">Information about utilities is not available.</p>}
                    </div>

                  </div>
                </div>


              </Col>

              <Col md={4}>
                <h4>Latest Listings</h4>
                <Card className="mb-4">
                  <Card.Img variant="top" src="https://via.placeholder.com/300x200" />
                  <Card.Body>
                    <Card.Text>
                      Listing description goes here for another property...
                    </Card.Text>
                  </Card.Body>
                </Card>
                {/* You can add more cards for other listings here */}
              </Col>
            </Row>

          </Container>
        </div>
      ) : (
        <div>No property details available</div>
      )}



    </div>
  );
};

export default Properties;
