import React from "react";
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from "react-router-dom"; // Use Routes instead of Switch
import 'bootstrap/dist/css/bootstrap.min.css';
import Properties from "./Components/Pages/Properties/Properties";
import HomeListing from "./Components/Pages/Properties/HomeListing";
import Navbarcomponent from "./Components/Pages/Navbar/Navbar";
import FooterComponent from "./Components/Pages/Footer/Footer";
import MediaList from "./Components/Pages/MediaList";
import PropertyList from "./Components/Pages/Properties/PropertyList";

function App() {

  return (
    // <Router>
    //   <Routes>
    //     <Route path="/sale_property_list" element={<SalePropertyList />}/>
    //     <Route path="/buy_property_list" element={<BuyPropertyList />}/>
    //     <Route path="/property_details/:listingKey" element={<Properties />} />
    //     <Route path="/home_listing" element={<HomeListing />}/>
    //     <Route path="/" element={<h1>Welcome to the Home Page</h1>} />
    //   </Routes>
    // </Router>
    <>
      <BrowserRouter>
        <Navbarcomponent />
        <Routes>
          <Route path="/property-list" element={<PropertyList />} />
          <Route path="/property-details/:listingKey" element={<Properties />} />
          <Route path="/home_listing" element={<HomeListing />} />
          <Route path="/media_list" element={<MediaList />} />

          <Route path="/" element={<h1>Welcome to the Home Page</h1>} />
        </Routes>
        <FooterComponent />
      </BrowserRouter>
    </>
  );
}

export default App;
